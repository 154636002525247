<template>
  <div class="admin-product-edit">
    <div class="toolbar justify-content-between">
      <div>
        <i class="icon-button" :class="$config.icons.general.back" v-b-tooltip title="Back to Admin" @click="clickBack"></i>
      </div>
    </div>
    <div class="list-containter">
      <b-card>
        <b-card-header><h2>Admin Shop Product Edit</h2></b-card-header>
        <b-card-body>
          <admin-edit-shop-product :shopProductId="shopProductId"></admin-edit-shop-product>
        </b-card-body>
      </b-card>
    </div>
  </div>
</template>

<script>
import AdminEditShopProduct from '@/components/admin/AdminEditShopProduct'
export default {
  name: 'admin-shop-product-edit-view',
  components: {
    AdminEditShopProduct
  },
  data: function () {
    return {
      shopProductId: null
    }
  },
  created () {
    if (this.$route.params.hasOwnProperty('shop_product_id')) {
      console.log('Viuew ROute: ', this.$route.params.shop_product_id)
      this.shopProductId = this.$route.params.shop_product_id
    }
  },
  methods: {
    clickBack: function () {
      this.$router.push({ path: '/admin' })
    }
  }
}
</script>

<style scoped lang="scss">
@import '../../variables';

.admin-product-edit {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 2%;
  overflow-y: auto;
}

.list-containter {
  background: $theme-color-background-4;
  border-radius: 5px;
  padding: 5px;
  margin: 5px;
}

.toolbar {
  width: 100%;
  display: flex;
  flex-direction: row;
  font-size: 3rem;
}

</style>
