var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "admin-product-edit" }, [
    _c("div", { staticClass: "toolbar justify-content-between" }, [
      _c("div", [
        _c("i", {
          directives: [{ name: "b-tooltip", rawName: "v-b-tooltip" }],
          staticClass: "icon-button",
          class: _vm.$config.icons.general.back,
          attrs: { title: "Back to Admin" },
          on: { click: _vm.clickBack }
        })
      ])
    ]),
    _c(
      "div",
      { staticClass: "list-containter" },
      [
        _c(
          "b-card",
          [
            _c("b-card-header", [
              _c("h2", [_vm._v("Admin Shop Product Edit")])
            ]),
            _c(
              "b-card-body",
              [
                _c("admin-edit-shop-product", {
                  attrs: { shopProductId: _vm.shopProductId }
                })
              ],
              1
            )
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }